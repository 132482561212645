import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// import { buildUrl } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Project"],
  endpoints: (builder) => ({
    getProject: builder.query({
      query: ({ id }) => `/api/project/${id}`,
      providesTags: ["Project"],
    }),
    // getProducts: builder.query({
    //   query: (params) => buildUrl("/products", params),
    //   providesTags: ["Products"],
    // }),
  }),
});

// export const { useGetProductQuery, useGetProductsQuery } = apiSlice;
export const { useGetProjectQuery } = apiSlice;
