import axios from 'axios';

const baseUrl = 'https://laravel.smartexweb.kz';
const projectsUrl = baseUrl + '/api/projects';
const categoriesUrl = baseUrl + '/api/categories';
const projectsByCategoryUrl = baseUrl + '/api/projectsByCategory';
const projectByIdUrl = baseUrl + '/api/project/';

export const ProjectsApiService = {
    async getAll() {
        const response = await axios.get(projectsUrl);

        return response.data
    }
}

export const CategoriesApiService = {
    async getAll() {
        const response = await axios.get(categoriesUrl);

        return response.data
    }
}

export const ProjectsByCategoryUrlApiService = {
    async getAll(category) {
        let response;
        if(category === 99) {
            response = await axios.get(projectsUrl);
        }
        else {
            response = await axios.get(projectsByCategoryUrl+'?category='+category);
        }

        return response.data
    }
}

export const ProjectByIdApiService = {
    async getProject(id) {
        const response = await axios.get(projectByIdUrl+id);

        return response.data
    }
}