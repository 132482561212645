import Hero from '../components/Hero';
import Services from "../components/Services";
import Technologies from "../components/Technologies";

function Home() {
    return (
        <>
            <main id="main">

                <Hero />

                <Services />

                <Technologies />

            </main>
            {/* End #main */}
        </>
    );
}

export default Home;