import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            {/* ======= Footer ======= */}
            <footer id="footer">

                {/* <div className="footer-newsletter">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <h4>Join Our Newsletter</h4>
                                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                                <form action="" method="post">
                                <input type="email" name="email" />
                                <input type="submit" value="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 footer-contact">
                                <h3>SMARTEXWEB</h3>
                                <p>
                                    Республика Казахстан <br />
                                    г. Павлодар<br />
                                    &nbsp; <br />
                                    <strong>Phone:</strong> +77088210530<br />
                                    <strong>Email:</strong> mihas-ox7-74@mail.ru<br />
                                </p>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Полезные ссылки</h4>
                                <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/">Главная</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/resume">Резюме</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/about">Личные данные</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/services">Технологии</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/projects">Проекты</Link></li>
                                </ul>
                            </div>

                            {/* <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Web Design</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Web Development</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Product Management</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Marketing</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Graphic Design</Link></li>
                                </ul>
                            </div> */}

                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Социальные сети</h4>
                                {/* <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p> */}
                                <div className="social-links mt-3">
                                <Link to="https://github.com/mseo-web" className="github" target="_blank"><i className="bi bi-github"></i></Link>
                                {/* <Link to="#" className="twitter"><i className="bx bxl-twitter"></i></Link>
                                <Link to="#" className="facebook"><i className="bx bxl-facebook"></i></Link>
                                <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                                <Link to="#" className="google-plus"><i className="bx bxl-skype"></i></Link>
                                <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container footer-bottom clearfix">
                    <div className="copyright">
                        &copy; Copyright <strong><span>SMARTEXWEB</span></strong>
                    </div>
                    <div className="credits">
                        {/* All the links in the footer should remain intact. */}
                        {/* You can delete the links only if you purchased the pro version. */}
                        {/* Licensing information: https://bootstrapmade.com/license/ */}
                        {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/ */}
                        Designed by <Link to="https://bootstrapmade.com/" target="_blank">BootstrapMade</Link>
                    </div>
                </div>
            </footer>
            {/* End Footer */}
        </>
    );
}

export default Footer;