import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Technologies() {
    return (
        <>
            {/* ======= Services Section ======= */}
            <section className="section mastering-services">
                <div className="container">
                    <div className="section-title">
                        <h2>Осваиваем технологии</h2>
                        <p>Технологии, которые мы изучаем</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <OwlCarousel 
                            className='owl-theme' 
                            loop 
                            items="3"
                            autoplay
                            margin={10} 
                            >
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/react1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/vuejs1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/laravel1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/django1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/october1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/swift1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/kotlin1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/flutter1.png" alt="" />
                                </div>
                                <div className='owl-item-box'>
                                    <img src="assets/img/technologies/ReactNative1.png" alt="" />
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                </div>
            </section>
            {/* End Services Section */}
        </>
    );
}

export default Technologies;