import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollToTop from "../components/ScrollToTop";
import ScrollToTopService from '../services/ScrollToTopService';


function Layout() {
    return (
        <>
            <Header />

            <Outlet />

            <div className="mt-3"></div>

            <Footer />

            <ScrollToTop />

            <ScrollToTopService />
        </>
    );
}

export default Layout;