import { useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetProjectQuery } from "../features/api/apiSlice";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import { ROUTES } from "../utils/routes";

const Project = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const { data, isLoading, isFetching, isSuccess } = useGetProjectQuery({ id });

    useEffect(() => {
        if (!isFetching && !isLoading && !isSuccess) {
            navigate('/');
        }
    }, [isLoading, isFetching, isSuccess, navigate]);

    console.log(data);

    return !data ? (
        <section className="preloader">Loading...</section>
    ) : (
        <>
            {/* ======= Breadcrumbs ======= */}
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">

                    <ol>
                    <li><Link to="/">Главная</Link></li>
                    <li>Страница проекта</li>
                    </ol>
                    <h2>Страница проекта</h2>

                </div>
            </section>
            {/* End Breadcrumbs */}
            {/* ======= Portfolio Details Section ======= */}
            <section id="portfolio-details" className="portfolio-details">
                <div className="container">
                {/* <img src={process.env.PUBLIC_URL + '/assets/img/SM2.605d2c8a.png'} /> 
                <img src={window.location.origin + '/assets/img/SM2.605d2c8a.png'} /> */}
                    <div className="row gy-4">

                        <div className="col-lg-8">
                            <div className="">
                                <div>
                                    <OwlCarousel 
                                    className='owl-theme' 
                                    loop 
                                    items="1"
                                    autoplay
                                    margin={10} 
                                    >
                                        {data.photo?.map((item, i) => {
                                            return (
                                                <div className="swiper-slide" key={i}>
                                                    <img src={'https://laravel.smartexweb.kz/storage/'+item.id+'/'+item.file_name} alt="" />
                                                </div>
                                            );
                                        })}

                                        {/* <img src={'https://laravel.smartexweb.kz/storage/'+data.id+'/'+data.file_name} alt="" /> */}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="portfolio-info">
                                <h3>{data.name}</h3>
                                <ul>
                                    <li><strong>Категория</strong>: {data.categories[0].name}</li>
                                    <li><strong>Заказчик</strong>: {data.client}</li>
                                    <li><strong>Должность</strong>: {data.spec}</li>
                                    <li><strong>Период</strong>: {data.period}</li>
                                    <li><strong>Сайт проекта</strong>: <Link to={data.siteurl} target="_blank">{data.siteurl}</Link></li>
                                </ul>
                            </div>
                            <div className="portfolio-description">
                                <h2>Описание проекта</h2>
                                <p>
                                    {data.description}
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* End Portfolio Details Section */}
        </>
    )
}

export default Project;