
function Services() {
    return (
        <>
            {/* ======= Services Section ======= */}
            {/* <section className="section services">
                <div className="container">
                    <div className="row justify-content-center text-center mb-4">
                    <div className="col-5">
                        <h3 className="h3 heading">Технологии</h3>
                        <p>Технологии, которые мы используем в своих проектах</p>
                    </div>
                    </div>
                    <div className="row">

                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <i className="bi bi-card-checklist"></i>
                        <h4 className="h4 mb-2">Web Design</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p>
                        <ul className="list-unstyled list-line">
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
                        <li>Non pariatur nisi</li>
                        <li>Magnam soluta quod</li>
                        <li>Lorem ipsum dolor</li>
                        <li>Cumque quae aliquam</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <i className="bi bi-binoculars"></i>
                        <h4 className="h4 mb-2">Mobile Applications</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p>
                        <ul className="list-unstyled list-line">
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
                        <li>Non pariatur nisi</li>
                        <li>Magnam soluta quod</li>
                        <li>Lorem ipsum dolor</li>
                        <li>Cumque quae aliquam</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <i className="bi bi-brightness-high"></i>
                        <h4 className="h4 mb-2">Graphic Design</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p>
                        <ul className="list-unstyled list-line">
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
                        <li>Non pariatur nisi</li>
                        <li>Magnam soluta quod</li>
                        <li>Lorem ipsum dolor</li>
                        <li>Cumque quae aliquam</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <i className="bi bi-calendar4-week"></i>
                        <h4 className="h4 mb-2">SEO</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p>
                        <ul className="list-unstyled list-line">
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
                        <li>Non pariatur nisi</li>
                        <li>Magnam soluta quod</li>
                        <li>Lorem ipsum dolor</li>
                        <li>Cumque quae aliquam</li>
                        </ul>
                    </div>
                    </div>
                </div>
            </section> */}
            {/* End Services Section */}

            {/* ======= Services Section ======= */}
            <section id="services" className="services mt-5">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Технологии</h2>
                        <p>Технологии, которые мы используем в своих проектах</p>
                    </div>

                    <div className="row">
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/wp-logo_500x500.png" alt="smartexweb" /></div>
                        <h4>WORDPRESS</h4>
                        <p>WordPress - это программное обеспечение с открытым исходным кодом, которое вы можете использовать для создания красивого веб-сайта, блога или приложения.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/cordova_logo1.png" alt="smartexweb" /></div>
                        <h4>APACHE CORDOVA</h4>
                        <p>Мобильные приложения с HTML , CSS и JS Таргетинг на несколько платформ с одной базой кода Бесплатный и открытый исходный код</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/1200px-electron_software_framework_logo.svg_.png" alt="smartexweb" /></div>
                        <h4>ELECTRON</h4>
                        <p>Electron использует Chromium и Node.js, так что Вы можете создавать свои приложения на HTML, CSS и JavaScript.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/kohana.png" alt="smartexweb" /></div>
                        <h4>KOHANA</h4>
                        <p>Kohana — это веб-фреймворк с открытым кодом, основанный на PHP5 и использующий концепцию HMVC (Hierarchical Model View Controller — Иерахические Модель-Вид-Контроллер). Его основные цели — быть безопасным, легким и простым в использовании, и это действительно так.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/laravel-logo.png" alt="smartexweb" /></div>
                        <h4>LARAVEL</h4>
                        <p>Laravel - это фреймворк для веб-приложений с выразительным элегантным синтаксисом. Мы считаем, что развитие должно быть приятным и творческим опытом, чтобы быть по-настоящему полезным. Laravel пытается снять боль с разработки, упрощая общие задачи, используемые в большинстве веб-проектов.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/netty_logo_600px.png" alt="smartexweb" /></div>
                        <h4>NETTY</h4>
                        <p>Инфраструктура клиент-сервер для разработки сетевых приложений Java, таких как серверы протоколов и клиенты. Асинхронная среда и инструменты сетевого приложения, управляемые событиями, используются для сетевого программирования.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/1200px-php-logo.svg_.png" alt="smartexweb" /></div>
                        <h4>PHP</h4>
                        <p>Скриптовый язык интенсивно применяемый для разработки веб-приложений. В настоящее время является одним из лидеров среди языков, применяющихся для создания динамических веб-сайтов.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/480px-unofficial_javascript_logo_2.svg_.png" alt="smartexweb" /></div>
                        <h4>JAVASCRIPT</h4>
                        <p>Обычно используется как встраиваемый язык для программного доступа к объектам приложений. Наиболее широкое применение находит в браузерах как язык сценариев для придания интерактивности веб-страницам</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box">
                        <div className="icon"><img src="assets/img/technologies/mysql.png" alt="smartexweb" /></div>
                        <h4>MYSQL</h4>
                        <p>Свободная реляционная система управления базами данных. Гибкость СУБД MySQL обеспечивается поддержкой большого количества типов таблиц. Разработку и поддержку MySQL осуществляет корпорация Oracle.</p>
                        </div>
                    </div>

                    </div>

                </div>
            </section>
            {/* End Services Section */}
        </>
    );
}

export default Services;