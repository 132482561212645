import Services from "../components/Services";
import Technologies from "../components/Technologies";

function ServicesPage() {
    return (
        <>
            <Services />
            <Technologies />
        </>
    );
}

export default ServicesPage;