
function Contact() {
    return (
        <>
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact mt-5">
                <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Контакты</h2>
                    {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                </div>

                <div className="row" data-aos="fade-in">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 d-flex align-items-stretch">
                        <div className="info">
                            <div className="address">
                            <i className="bi bi-geo-alt"></i>
                            <h4>Адрес:</h4>
                            <p>Республика Казахстан, г. Павлодар</p>
                            </div>

                            <div className="email">
                            <i className="bi bi-envelope"></i>
                            <h4>Email:</h4>
                            <p>mihas-ox7-74@mail.ru</p>
                            </div>

                            <div className="phone">
                            <i className="bi bi-phone"></i>
                            <h4>Телефон:</h4>
                            <p>+77088210530</p>
                            </div>

                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d8182.658543007002!2d76.96528683783558!3d52.28713847593566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2skz!4v1590232255628!5m2!1sru!2skz" 
                            title="myFrame"
                            width="100%" 
                            height="290px" 
                            // frameBorder="0"
                            style={{ border: 0}}
                            allowFullScreen=""></iframe>
                        </div>
                    </div>

                    {/* <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                        <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">Your Name</label>
                                <input type="text" name="name" className="form-control" id="name" required />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="name">Your Email</label>
                                <input type="email" className="form-control" name="email" id="email" required />
                            </div>
                            </div>
                            <div className="form-group">
                            <label htmlFor="name">Subject</label>
                            <input type="text" className="form-control" name="subject" id="subject" required />
                            </div>
                            <div className="form-group">
                            <label htmlFor="name">Message</label>
                            <textarea className="form-control" name="message" rows="10" required></textarea>
                            </div>
                            <div className="my-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>
                    </div> */}

                </div>

                </div>
            </section>
            {/* End Contact Section */}

        </>
    );
}

export default Contact;