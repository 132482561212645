import { Routes, Route } from 'react-router-dom';

import  Home  from './pages/Home';
import  About  from './pages/About';
import  Contact  from './pages/Contact';
import  Projects  from './pages/Projects';
import  Project  from './pages/Project';
import  Resume  from './pages/Resume';
import  ServicesPage  from './pages/ServicesPage';
import  Notfoundpage  from './pages/Notfoundpage';

import Layout from './layouts/Layout';

function App() {
  return (
    <>
        <Routes>
            <Route path="/" element={<Layout /> }>
              <Route index element={<Home /> } />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="projects" element={<Projects />} />
              <Route path="project/:id" element={<Project />} />
              <Route path="resume" element={<Resume />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="*" element={<Notfoundpage />} />
            </Route>
        </Routes>
    </>
  );
}

export default App;
