import { Link, NavLink, useNavigate } from 'react-router-dom';

function Header() {
    let navigate = useNavigate();

    function refreshPage() {
        navigate("/", { replace: true });
        window.location.reload(true);
    }

    const handleCloseMenu = () => {
        console.log('mobile-nav-toggle');
        document.getElementById('navbar').classList.toggle('navbar-mobile')
        document.getElementById('hamburger').classList.toggle('bi-list')
        document.getElementById('hamburger').classList.toggle('bi-x')
    };

    const menuToggle = () => {
        console.log('menuToggle');
        let nav = document.getElementById("navbar")
        if(nav.classList.contains("navbar-mobile")){
            nav.classList.toggle('navbar-mobile')
            document.getElementById('hamburger').classList.toggle('bi-list')
            document.getElementById('hamburger').classList.toggle('bi-x')
        }
    };

    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">

                {/* <h1 className="logo me-auto"><Link to="index.html">Arsha</Link></h1> */}
                {/* Uncomment below if you prefer to use an image logo */}
                {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}
                <div className="logo me-auto" onClick={refreshPage}><img src="assets/img/SM-logo2.png" alt="" className="img-fluid" /></div>

                <nav id="navbar" className="navbar">
                    <ul onClick={() => menuToggle()}>
                    <li><NavLink className="nav-link" to="/">Главная</NavLink></li>
                    {/* <li><NavLink className="nav-link" to="/resume">Резюме</NavLink></li> */}
                    <li><NavLink className="nav-link" to="/about">Личные данные</NavLink></li>
                    <li><NavLink className="nav-link" to="/services">Технологии</NavLink></li>
                    <li><NavLink className="nav-link" to="/projects">Проекты</NavLink></li>
                    {/* <li><NavLink className="nav-link" to="/portfolio">Порфолио</NavLink></li> */}
                    <li><NavLink className="nav-link" to="/contact">Контакты</NavLink></li>
                    {/* <li className="dropdown"><Link to="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></Link>
                        <ul>
                        <li><Link to="#">Drop Down 1</Link></li>
                        <li className="dropdown"><Link to="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></Link>
                            <ul>
                            <li><Link to="#">Deep Drop Down 1</Link></li>
                            <li><Link to="#">Deep Drop Down 2</Link></li>
                            <li><Link to="#">Deep Drop Down 3</Link></li>
                            <li><Link to="#">Deep Drop Down 4</Link></li>
                            <li><Link to="#">Deep Drop Down 5</Link></li>
                            </ul>
                        </li>
                        <li><Link to="#">Drop Down 2</Link></li>
                        <li><Link to="#">Drop Down 3</Link></li>
                        <li><Link to="#">Drop Down 4</Link></li>
                        </ul>
                    </li>
                    <li><Link className="nav-link scrollto" to="#contact">Contact</Link></li> */}
                    <li><Link className="getstarted" to="/resume">Резюме</Link></li>
                    </ul>
                    <i id="hamburger" className="bi bi-list mobile-nav-toggle" onClick={() => handleCloseMenu()}></i>
                </nav>
                {/* navbar */}

                </div>
            </header>
            {/* End Header */}
        </>
    );
}

export default Header;