import { Link } from 'react-router-dom';

function Hero() {
    return (
        <>
            {/* ======= Hero Section ======= */}
            <section id="hero" className="d-flex align-items-center">

            <div className="container">
                <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h1>Сергей Михайлов</h1>
                    <h2>FULL STACK РАЗРАБОТЧИК</h2>
                    <div className="d-flex justify-content-center justify-content-lg-start">
                    <Link to="/projects" className="btn-get-started scrollto">Проекты</Link>
                    {/* <Link to="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></Link> */}
                    </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                    <img src="assets/img/hero-img.png" className="img-fluid animated" alt="" />
                </div>
                </div>
            </div>

            </section>
            {/* End Hero */}
        </>
    );
}

export default Hero;