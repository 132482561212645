import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { ProjectsByCategoryUrlApiService } from "../services/ApiService";
import { CategoriesApiService } from "../services/ApiService";

function Projects() {

    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentcategory, setCurrentcategory] = useState('');

    const [active, setActive] = useState(false);

    useEffect(() => {
        const fetchProjectsData = async (category) => {
            const data = await ProjectsByCategoryUrlApiService.getAll(category);

            setProjects(data);
        }
        fetchProjectsData(99);
        setCurrentcategory('99');
        setActive(99);
    }, []);
    console.log(projects);

    useEffect(() => {
        const fetchCategoriesData = async () => {
            const data = await CategoriesApiService.getAll();

            setCategories(data);
        }
        fetchCategoriesData()
    }, []);
    // console.log(categories);

    const fetchProjectsByCategory = (category) => {
        const fetchProjectsByCategoryData = async (category) => {
            const data = await ProjectsByCategoryUrlApiService.getAll(category);

            setProjects(data);
        }
        const setCurrentcategoryData = async (category) => {
            setCurrentcategory(category);
        }
        fetchProjectsByCategoryData(category);
        setCurrentcategoryData(category);
        setActive(category);
    };

    console.log(currentcategory);

    return (
        <>
            {/* ======= Portfolio Section ======= */}
            <section id="portfolio" className="portfolio mt-5">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Проекты</h2>
                        {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                    </div>

                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <ul id="portfolio-flters" className="d-flex justify-content-center flex-wrap" data-aos="fade-up" data-aos-delay="100">
                                {/* <li id="99" onClick={() => fetchProjectsByCategory(99)}>Все</li>
                                {categories.data?.map((item, i) => {
                                    return (
                                        <li key={i} id={item.name} onClick={() => fetchProjectsByCategory(item.id)}>{item.name}</li>
                                    );
                                })} */}

                                <li 
                                id="99" 
                                onClick={() => fetchProjectsByCategory(99)} 
                                className={`${active === 99 && 'filter-active'}`}
                                >
                                    Все
                                </li>
                                {categories.data?.map((item, i) => {
                                    return (
                                        <li 
                                        key={i} 
                                        id={item.name} 
                                        onClick={() => fetchProjectsByCategory(item.id)} 
                                        className={`${active === item.id && 'filter-active'}`}
                                        >
                                            {item.name}
                                        </li>
                                    );
                                })}

                                {/* <li id="99" className={currentcategory==='99' ? 'filter-active' : ' '} onClick={() => fetchProjectsByCategory(99)}>Все</li>
                                {categories.data?.map((item, i) => {
                                    return (
                                        <li key={i} id={item.name} className={currentcategory===item.name ? 'filter-active' : ' '} onClick={() => fetchProjectsByCategory(item.id)}>{item.name}</li>
                                    );
                                })} */}
                            </ul>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>

                    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                        {projects.data?.map((item, i) => {
                            return (
                                <div className="col-lg-4 col-md-6 portfolio-item filter-app" key={i}>
                                    <div className="portfolio-img">
                                        <img src={'https://laravel.smartexweb.kz/storage/'+item.photo[0].id+'/'+item.photo[0].file_name} className="img-fluid" alt="" />
                                    </div>
                                    <div className="portfolio-info">
                                        <h4>{item?.name}</h4>
                                        {/* <p>App</p> */}
                                        {/* <Link to="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 1"><i className="bx bx-plus"></i></Link> */}
                                        <Link to={"/project/"+item.id} className="details-link" title="Подробнее"><i className="bx bx-link"></i></Link>
                                    </div>
                                </div>
                            );
                        })}
                        
                    </div>
                </div>
            </section>
            {/* End Portfolio Section */}
        </>
    );
}

export default Projects;